@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

body {
  font-family: 'Roboto', sans-serif;
}

/* .body{
  position: absolute;
  width: 100%;
  overflow: hidden;
} */
.knee-component {
  font-family: 'Roboto', sans-serif;
}
.navbar {
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px 0 #d9d9d9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.loader-effects {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.logout {
  float: right;
  margin: 0.5rem;
  margin-left: 0;
}
.homepage-navbar {
  width: 6%;
  margin: 1rem;
}

.avatar {
  float: right;
  margin: 0.5rem;
  border-radius: 30px;
}

/* .table-container table thead {
  position: sticky;
  top: 0;
  background-color: #ffffff; 
  z-index: 1;
} */

.findings-head {
  font-weight: 600;
  font-size: 16px;
  margin-left: -5px;
}

.ratio-container {
  background-color: #d9d9d9;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 10px;
}

.ratio-wrapper {
  margin-top: 0.5rem;
}

.p-card .p-card-body {
  padding-top: 10px;
  padding-bottom: 0;
  padding-right: 20px;
}

.table-head {
  margin-top: 2rem;
  margin-left: 50px;
  margin-right: 50px;
}

th {
  border: none;
  background-color: #343a40;
  color: white;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
}
th:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
th:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
td {
  border: none;
  background-color: #f8f9fa;
}
.p-column-filter-menu-button {
  color: white;
}
.p-column-filter-menu-button:focus {
  background-color: #495057;
}
/* .p-component {
  border-radius: 10px;
} */
.p-column-filter-operator {
  display: none;
}
.p-column-filter-matchmode-dropdown {
  display: none;
}
.p-column-filter-add-rule {
  display: none;
}

.time-part {
  background-color: #1b263b;
  margin-top: 5px;
  border-radius: 20px;
  width: 67px;
  color: #f8f9fa;
  font-size: 13px;
  padding: 2px 0px 3px 2px;
}

/* report.css */
.left-container {
  background-color: white;
  height: 100vh;
  width: 29%;
}
.findings-wrapper {
  background-color: #d9d9d9;
  width: 100%;
  border-radius: 0 0 20px 20px;
  height: 53%;
  overflow-y: auto;
}
.knee-findings-wrapper {
  background-color: #d9d9d9;
  width: 100%;
  border-radius: 0 0 20px 20px;
}
.side-container {
  margin: 6px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px;
}

.side-wrapper {
  background-color: #d9d9d9;
  width: 100%;
  height: 100%;
}
.radiobutton-container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin: 1rem;
}
.bone-wrapper {
  background-color: #d9d9d9;
  width: 100%;
  border-radius: 0 20px 0 0;
}
.bone-radio-container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin: 0.8rem;
}

.findings-head-rib {
  font-weight: 600;
  font-size: 16px;
  margin-top: 1rem;
  margin-left: 0.6rem;
}
.rib-wrapper {
  background-color: #d9d9d9;
  width: 100%;
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
}
.rib-radio-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.2rem;
  margin: 0.5rem;
}
.zone2-container {
  background-color: #d9d9d9;
  width: 100%;
  margin-top: 6px;
  border-radius: 0 0 20px 20px;
}
.grade-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap: 1rem;
  margin: 1rem;
}
.action-container {
  display: flex;
  gap: 13rem;
  margin: 1rem;
  margin-top: 10px;
  justify-content: space-evenly;
}

.clear-action {
  background-color: #e9e9e9;
  border-color: #d9d9d9;
  color: black;
  padding: 10px 30px 10px 30px;
}
/* .clear-action:active{
  border-color: #e9e9e9;
} */
.add-action {
  background-color: #0d1b2a;
  border-color: #0d1b2a;
  padding: 10px 30px 10px 30px;
}

.right-container {
  width: 100%;
}

.top-section {
  width: 100%;
}
.findings-wrapper-right {
  margin: 1rem;
}
.selected-findings-section {
  background-color: white;
  width: 85%;
  border-radius: 0 0 20px 20px;
  margin-left: 0.5rem;
}
.action-section {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  row-gap: 2.5rem;
}
.chip-component {
  margin-top: 15px;
  margin-left: 8px;
}
.dicom-container {
  width: 99%;
  height: 77%;
  background-color: white;
  margin-left: 0.5rem;
  margin-top: 1rem;
  border-radius: 20px 0 0px 20px;
}
.history-section {
  padding: 1rem;
  border-radius: 20px;
}
.history-wrapper {
  display: flex;
}
.dicom-viewer {
  width: 100%;
  height: 80%;
}
.dicom-wrapper {
  /* margin:1rem; */
  height: 100%;
}
.viewer-container {
  height: 59vh;
}
.zoom-container {
  background-color: transparent;
  width: 99%;
  padding: 0.5rem;
}
.pi {
  font-size: 22px;
}
.pi-window-maximize {
  float: right;
  cursor: pointer;
}
.pi-window-minimize {
  float: right;
  cursor: pointer;
  color: white;
}
/* .pi-window-minimize:hover{
  background-color: rgb(0, 255, 255);
} */
.fullscreen-container {
  height: 100%;
}
.action-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  text-decoration: none;
}
.chip-style {
  margin: 0.1rem;
}
.p-column-filter-buttonbar {
  display: none;
}

sup {
  font-size: 11px;
  margin-left: 3px;
  margin-bottom: 5px;
}

/* login.css */

.prodigi-img {
  width: 45%;
}

.breaker {
  margin-top: -15px;
  font-size: 20px;
  margin-bottom: 20px;
}

.login-container {
  border: solid 2px white;
  margin: 8rem;
  border-radius: 10px;
  background-color: white;
}

.Schatzker-wrapper {
  background-color: #d9d9d9;
  height: 20vh;
  overflow-y: scroll;
  border: 1px solid #ccc;
  width: 100%;
}

.Schatzker-radio-container {
  padding: 10px;
}

.pathology-wrapper {
  background-color: #d9d9d9;
  height: 100%;
  overflow-y: scroll;
}

.pathology-radio-container {
  padding: 10px;
}
.findings-wrapper-knee {
  width: 100%;
  border-radius: 0 0 20px 20px;
  height: 19%;
  overflow-y: auto;
}

.left-wrapper {
  position: fixed;
  height: 100vh;
  width: 29%;
}

.patterntype-wrapper {
  background-color: #d9d9d9;
  border: 1px solid #ccc;
  width: 100%;
}

.patterntype-radio-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.left-container-chest {
  background-color: white;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.right-container-chest {
  width: 100%;
  margin-left: 28rem;
}

.case-hyper-link:focus {
  color: #22c55e;
  text-decoration: none;
}

.p-input-icon-left {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.flex-horizontal {
  display: flex;
}

/* @media screen and (max-width: 500px) {

  .right-container-chest{
    margin-left: 23rem;
  }

  .action-container{
    gap:8rem;
  }
} */

@media screen and (min-width: 1772px) {
  /* .body{
    position: absolute;
    width: 100%;
    overflow: hidden;
  } */
  .findings-wrapper-knee {
    height: 28%;
  }
  html,
  body {
    overflow-x: hidden;
  }
  body {
    position: relative;
    width: 100%;
  }

  .findings-wrapper {
    height: 39%;
  }
  .dicom-container {
    height: 84%;
  }
  .top-section {
    gap: 2rem;
  }

  .login-container {
    margin: 14rem;
  }

  .Schatzker-wrapper {
    height: 17vh;
  }
  .right-container-chest {
    margin-left: 35rem;
  }
}
